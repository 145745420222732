.socialLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--white);
    color: var(--black);
    position: relative;
    line-height: 2rem;
    padding: .5rem 2rem;
    box-sizing: border-box;
    &__ico {
        position: absolute;
        top: 50%;
        margin-top: -1rem;
        left: 0;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: var(--theme-main);
        margin-right: 1rem;
        border-radius: 2rem;
        margin-right: auto;
        img {
            display: block;
        }
    }

    & + & {
        margin-top: 1rem;
    }
}
