.spending {
    display: block;
    background: var(--light-blue);
    min-height: 100%;
}
.btnwrapper {
    margin-top: -1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
