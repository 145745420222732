.upload {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    height: calc(100dvh - var(--nav-height));
}

.btn-wrapper {
    display: block;
    margin: 1rem auto;
}

.form {
    padding-top: 1rem;
    padding: 1rem;
    background: var(--white);
}

.nest {
    background-color: var(--light-blue);
    padding: 1rem;
    margin-top: 1rem;
    h3 {
        margin-top: 0;
    }
}

.deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

.uploadother {
    margin-bottom: 1rem;
    cursor: pointer;
    color: var(--theme-main);
    text-decoration: underline;
}

.filedetails {
    margin-bottom: 1rem;
    th, td {
        padding: .5rem;
        max-width: 200px;
        word-break: break-all;
        text-align: left;
        vertical-align: top;
    }
}

.upload {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--light-blue);
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../public/images/1062.jpg') center center repeat;
        background-size: cover !important;
        content: '';
        z-index: 1;
        mix-blend-mode: darken;
        opacity: .02;
        pointer-events: none;
    }
    p {
        position: relative;
        z-index: 3;
    }
    &__input {
        position: relative;
        z-index: 3;
        &__input {
            position: absolute;
            top: -100000px;
            left: -100000px;
        }
        &__img {
            background: var(--theme-main);
            border-radius: 10rem;
            width: 10rem;
            height: 10rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
            img {
                width: 5rem;
            }
        }
    }
}

.title {
    position: relative;
    z-index: 3;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
