.btn {
    background: var(--theme-main);
    color: var(--white);
    border: 0;
    padding: .5rem 2rem;
    border-radius: 50px;
    background: var(--theme-main) url('../../../../public/images/pattern.svg') right center no-repeat;
    background-size: contain !important;
    cursor: pointer;
    transition: all 200ms ease-in;
    border: 2px solid var(--theme-main);
    display: inline-block;

    &:hover {
        background-color: var(--light-blue);
        color: var(--theme-main);
    }
}
