.dailyTask {
    display: block;
    padding: 1rem;
    position: relative;
    background: var(--light-blue);
    z-index: 5;
    border-radius: 50% 50% 0 0 ;
}

.title {
    font-size: 1.2rem;
    text-align: left;
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    margin-top: 0;
}

.task {
    border-radius: 2rem;
    overflow: hidden;

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        &__txt {
            padding-left: 1rem;
            &__name {
                font-size: 1.5rem;
                font-weight: bold;
            }
            &__age {
                font-size: 1rem;
            }
        }
    }
    &__btm {
        &__task {
            width: 100%;
            background-color: var(--white);
            margin-bottom: .5rem;
            outline: none;
            text-align: center;
            position: relative;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            padding: 1rem;
            overflow: hidden;
            font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
            transition: all 500ms ease-in;
            &:nth-child(odd) {
                border-radius: 30% 10% 30% 10%;
            }
            &:nth-child(even) {
                border-radius: 10% 30% 10% 30%;
            }
            &__ico {
                background: var(--theme-main);
                padding: .5rem;
                width: 2rem;
                height: 2rem;
                border-radius: 5rem;
                margin-right: 1rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 5;
                img {
                    width: 80%;
                    filter: brightness(10);
                }
            }
            &__txt {
                position: relative;
                z-index: 5;
                text-align: left;
                width: 80%;
            }
          
           input[type='checkbox'] {
                border: 0;
                width: 2rem;
                height: 2rem;
                border-radius: 2rem;
                position: absolute;
                top: 50%;
                margin-top: -1rem;
                right: 1rem;
            }
            // &::before {
            //     position: absolute;
            //     z-index: 1;
            //     top: 50%;
            //     transform: translateY(-50%);
            //     right: 1rem;
            //     content: '';
            //     display: block;
            //     width: 3rem;
            //     height: 3rem;
            //     background: url('../../../public/images/ico/inactive.svg') center center no-repeat;
            //     background-size: contain !important;
            //     transition: all 500ms ease-in;
            // }
            // &.active {
            //     color: var(--white);
            //     &::before {
            //         width: 50rem;
            //         right: -50%;
            //         height: 50rem;
            //         background: url('../../../public/images/ico/active.svg') center center no-repeat;
            //     }
            // }
        }
    }
}

.pt {
    text-align: left;
    font-size: .5em;
    display: block;
}
