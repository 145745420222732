
:root {
    --hero-content-padding-right: 2rem;
    --hero-subtitle-margin-top: 0;
    --hero-subtitle-margin-bottom: 0;
    --hero-subtitle-font-size: 2rem;
    --videowrapper-width: 60%;
    --hero-text-align: left;
    --hero-logo-margin: 0 0 1rem;
    --hero-logo-display: block;
    --hero-content-width: 40%;
    --hero-container-flex-direction: row;
    --hero-container-display: flex;
    @media screen and (max-width: 818px) {
        --hero-content-padding-right: 0;
        --hero-subtitle-margin-top: 1rem;
        --hero-subtitle-margin-bottom: 0rem;
        --hero-subtitle-font-size: 1rem;
        --videowrapper-width: 80%;
        --hero-text-align: center;
        --hero-logo-display: none;
        --hero-logo-margin: 0 auto 1rem;
        --hero-content-width: 100%;
        --hero-container-flex-direction: column-reverse;
    }
}
.hero {
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: var(--light-blue);
    overflow: hidden;
    position: relative;
    text-align: var(--hero-text-align);


    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../../public/images/1062.jpg') center center no-repeat;
        background-size: cover !important;
        opacity: .02;
        pointer-events: none;
    }
}

.logo {
    display: var(--hero-logo-display);
    width: 100px;
    margin: var(--hero-logo-margin);
}

.container {
    display: var(--hero-container-display)  !important;
    flex-direction: var(--hero-container-flex-direction);
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
}

.content {
    width: var(--hero-content-width);
    box-sizing: border-box;
    padding-right: var(--hero-content-padding-right);
}

.videowrapper {
    width: var(--videowrapper-width);
}

.subtitle, .title, .txt {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5em;
}

.subtitle, .title {
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.subtitle {
    font-size: var(--hero-subtitle-font-size);
    margin-top: var(--hero-subtitle-margin-top);
    margin-bottom: var(--hero-subtitle-margin-bottom);
}

.title {
    font-size: 4rem;
    color: var(--theme-main);

    @media screen and (max-width: 818px) {
        font-size: 2.5rem;
    }
}

.txt {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.fixedVideoWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    z-index: 1500;
}

.innerwrapper {
    max-width: 800px;
    width: 100%;
}

.video {
    position: relative;
    width: 100%;
    padding-top: #{9 / 16 * 100%};
    display: block;

    video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.close {
    position: absolute;
    right: 0;
    bottom: calc(100% + 1rem);
    background: transparent;
    border: 0;
    cursor: pointer;
    color: var(--white);
}
