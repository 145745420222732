.chat {
    display: block;
    position: relative;
    background: var(--light-grey);
    padding-bottom: 80px;
    height: calc(100dvh - var(--nav-height));
    box-sizing: border-box;

    &__top {
        position: relative;
        background: url('../../../public/images/chat-top-bg.svg') right center no-repeat;
        background-size: cover;
        height: 18vh;
        border-radius: 0 0 80% 80%;
        &__back, &__txt {
            position: absolute;
            left: 1rem;
        }
        &__back {
            top: 1rem;
            background: var(--white);
            width: 40px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            outline: 0;
        }
        &__txt {
            top: 1rem;
            font-size: 2rem;
            max-width: 80%;
            font-weight: bold;
        }
    }
    &__btm {
        &__chat {
            box-sizing: border-box;
            padding: 1.5rem;
            max-height: calc(82vh - 80px - var(--nav-height));
            overflow-y: auto;
        }
    }
    &__input {
        box-sizing: border-box;
        height: 80px;
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: -2dvh;
        left: 0;
        background: var(--theme-main);
        padding: 1rem;
        border-radius: 30% 30% 0 0;
        &__input {
            flex: 0 0 calc(100% - 3rem);
        }
        &__send {
            padding-left: 0.5rem;
            button {
                transition: all 500ms ease;
                background: var(--white);
                border: 1px solid var(--theme-main);
                outline: 0;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background: var(--theme-main);
                    border: 1px solid var(--white);
                }
            }
        }
    }
}
