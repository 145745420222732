.profile {
    display: block;
    text-align: left;
    padding: 3rem .5rem 1.2rem;
    // padding: 1rem 1rem 1rem;
    // border-radius: 1rem;
    // border: 5px solid var(--theme-main);
    background: var(--sky-blue);
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    position: relative;
    &__img {
        position: absolute;
        top: 3rem;
        right: 1rem;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        border: 2px solid var(--white);
        // transform: translateY(-50%);
        box-shadow: 0 0 5px 5px rgba(0,0,0,.1);

        &__img {
            width: 100%;
        }
    }
    &__txt {
        font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
        &__name {
            font-weight: bold;
            color: var(--white);
            font-size: .8em;
        }
        &__lv {
            margin-top: .3rem;
            color: var(--white);
            font-size: .8em;
            margin-bottom: 0;
            span {
                font-size: 1.5rem;
                display: inline-block;
                vertical-align: bottom;
                line-height: 1.5rem;
            }
        }
    }
    &__pic {
        position: relative;
        margin-top: 1rem;
        &::after {
            background: url('../../../public/images/vet-background.png') center center repeat;
            background-size: cover;
            content: '';
            position: absolute;
            top: 25%;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            display: block;
            z-index: 1;
            mix-blend-mode: multiply;
        }
        img {
            display: block;
            position: relative;
            z-index: 2;
            width: 50%;
            margin: 0 auto;
        }
    }
}
