.txtImg {
    display: block;
}

.container {
    padding: 2rem 1rem;
    box-sizing: border-box;
    &.container {
        display: flex;
        align-items: center;
        max-width: var(--web-inner-width);

        @media screen and (max-width: 818px) {
            display: block;
        }
    }
}

.txt, .img {
    flex: 0 0 50%;
}
.txt {
    padding-right: 2rem;
    box-sizing: border-box;
}

.title {
    font-size: 2rem;
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: var(--theme-main);
}
