.premium {
    display: block;
    height: 30rem;
    width: 100%;
    overflow: hidden;
    background: var(--light-blue);
}

.container {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
    &.container {
        display: flex;
        max-width: var(--web-inner-width);
        @media screen and (max-width: 818px) {
            flex-wrap: wrap;
        }
    }
}
.txt, .images {
    flex: 0 0 50%;
    @media screen and (max-width: 818px) {
        flex: 0 0 100%;
    }
}
.txt {
    padding-right: 5rem;
    box-sizing: border-box;
}

.title {
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: var(--theme-main);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.text {
    font-size: 1.5rem;
}

.image {
    &s {
        height: 30rem;
        position: relative;
    }
    &1, &2 {
        position: absolute;
        max-width: none;
    }
    &1 {
        height: 25rem;
        transform: rotate(-45deg) translate(-20%, -5%);
    }
    &2 {
        height: 15rem;
        transform: rotate(-45deg) translate(30%, 50%);
    }
}
