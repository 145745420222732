.routine {
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
}

.img {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.btn {
    &wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 1rem 0;
        box-sizing: border-box;
        background-color: var(--white);
        border-radius: 2rem 2rem 0 0;
    }
    &inwrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.details {
    width: 100%;
    position: relative;
    z-index: 1;
    &wrapper {
        width: 100%;
        height: 10rem;
        overflow: hidden;
        display: block;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 10rem;
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        }
    }
}

.inputwrapper {
    position: absolute;
    z-index: 2;
    top: 2rem;
    left: 1rem;
    right: 1rem;
}
