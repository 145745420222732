.workingWith {
    display: block;
    padding: 2rem 1rem;
    // background: var(--light-grey);
}

.container {
    margin: 0 auto;
    &.container {
        max-width: var(--web-inner-width);
    }
}

.title {
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: var(--theme-main);
    margin-bottom: 1rem;
}

.logo-lists {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;
    img {
        height: 4rem;
    }
}
