.login {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(ellipse at center,  #7acded 0%,#32c5ff 100%);
    height: 100dvh;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    position: relative;

    &::after, &::before {
        content: '';
        position: absolute;
        width: 250px;
        height: 250px;
        border: 1px var(--white) solid;
        z-index: 1;
    }

    &::after {
        left: -10px;
        top: -10px;
        border-radius: 89% 11% 27% 73% / 69% 31% 69% 31% ;
    }
    &::before {
        right: -180px;
        bottom: -180px;
        transform: rotate(45deg);
    }
}

.link {
    color: var(--white);
}
.top, .btm {
    position: relative;
    z-index: 2;
}
.top {
    display: block;
    background: var(--manhattan);
    width: 260px;
    height: 220px;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
    position: relative;
    margin-bottom: -10px;
    &__wrapper {
        width: 280px;
        height: 280px;
        border-radius: 30% 70% 62% 38% / 18% 15% 85% 82% ;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
        filter: drop-shadow(2px 4px 6px rgba(0,0,0,.2));
    }
    &__img {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 300px;
        display: block;
        margin: 0 auto;
    }
}

.btm {
    padding: 1rem;
    text-align: center;
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    &__title, &__subtitle {
        color: var(--white);
    }
    &__title {
        font-size: 24px;
        margin-bottom: 0;
    }
    &__subtitle {
        margin-top: 0;
        font-weight: normal;
        font-size: 18px;
    }
    &__sub {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__link {
            color: var(--white);
        }
    }
    &__btnwrapper {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}
