
.conversation {
    --conversation-head-justify-content: start;
    &.right {
        --conversation-head-justify-content: end;
    }
    display: block;
    & + & {
        margin-top: 1rem;
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: var(--conversation-head-justify-content);
        margin-bottom: 1rem;
        &__img {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        &__txt {
            padding-left: .5rem;
            font-size: 1rem;
            font-weight: bold;
        }
    }
    &__body {
        background: var(--white);
        padding: 1rem;
        border-radius: 1rem;
        line-height: 1.5em;
    }
}
