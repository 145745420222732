
:root {
    --social-title-font-size: 2rem;
    @media screen and (max-width: 818px) {
        --social-title-font-size: 1rem;
    }
}

.social {
    display: block;
    padding: 1rem 1rem 2rem;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.title {
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: var(--theme-main);
    font-size: --social-title-font-size;
    margin-bottom: 1rem;
    margin-top: 0;
}

.content {
    text-align: center;
}

.img {
    max-width: 800px;
    margin: 1rem auto 0;
    display: block;
    .innerwrapper {
        width: 100%;
        padding-top: #{301/430*100%};
        position: relative;
        display: block;
        .bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 11;
        }
    }
}

.imgdetail {
    z-index: 10;
    position: absolute;
    width: 20%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;

    img {
        width: 100%;
    }
}

.btnwrapper {
    margin-top: 1rem;
    text-align: center;
}
