.registrationForm {
    padding: 1rem;
    background: var(--white);
    input {
        width: 100%;
        display: block;
        background-color: var(--white);
    }
}

.btnWrapper {
    margin-top: 2rem;
    text-align: center;
}
