.reportForm {
    display: block;
}

.title {
    font-size: 1.5rem;
    color: var(--theme-main);
}

.head, .body {
    padding: 1rem;
}

.head {
    background: var(--light-blue);
}

.label {
    display: block;
    margin-bottom: 0.5rem;
}

.input {
    margin-bottom: 1rem;
}
