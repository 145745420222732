.video {
    display: block;
    width: 100%;
    position: relative;
    padding-top: #{301/430*100%};
    > div {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        opacity: 0;
        &.ref3 {
            opacity: 1;
        }
    }
    img {
        width: 100%;
    }
}
