.input {
    & + & {
        margin-top: .5rem;
    }
    &__label {
        display: block;
        margin-bottom: .5rem;
    }
    &__input {
        border: 2px var(--theme-main) solid;
        border-radius: 50px;
        outline: none;
        line-height: 36px;
        width: 100%;
        box-sizing: border-box;
        font-size: 1rem;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;

        &.left {
            text-align: left;
        }
    }
}
