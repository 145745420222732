.nav {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 500;
    width: var(--container-width);
    background: var(--theme-main);
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
    left: 50%;
    bottom: 0;
    height: var(--nav-height);
    color: var(--white);
    transform: translateX(-50%);
    > a {
        width: 17vw;
    }
    * {
        color: var(--white);
        text-align: center;
    }
    &__txt {
        display: block;
        text-align: center;
        font-size: 8px;
        margin-top: 2px;
        text-transform: uppercase;
    }
}

.ico {
    height: calc(var(--nav-height) / 2.5);
    filter: brightness(100);
}
