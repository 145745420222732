$upload-border: 2px dotted var(--grey);
:root {
    --web-container-width: 1440px;
    --web-inner-width: 1024px;
    --container-width: 580px;
    --theme-main: #32c5ff;
    --black: #333333;
    --white: #ffffff;
    --light-grey: #f4f4f4;
    --grey: #5e5e5e;
    --light-blue: #e1f7ff;
    --sky-blue: #7acded;
    --nav-height: 4rem;
    --manhattan: #f4c698;
    --red: #ff2424;

    // web
    --header-height: 5rem;
    --tfont: "Poppins", Arial, "Helvetica Neue", Helvetica, sans-serif;
}

body {
    margin: 0;
    font-family: 'Gruppo',Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: bold;
}

.container {
    color: var(--black);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: var(--web-container-width);
}

.App {
    &__container {
        max-width: var(--container-width);
        height: 100dvh;
        position: relative;
        padding-bottom: var(--nav-height);
        box-sizing: border-box;
        background: var(--theme-main);
    }
}

.wrapper {
    &--app {
        height: 100%;
        overflow-y: auto;
        -ms-overflow-style: none; /* For Internet Explorer and Edge */
        scrollbar-width: none; /* For Firefox */
        &::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
        }
    }
}

a {
    color: var(--theme-main);
    text-decoration: none;
}

*::-webkit-scrollbar {
    display: none;
}

.blue {
    color: var(--theme-main);
}

img {
    max-width: 100%;
}

textarea {
    padding: 1rem;
    border: $upload-border;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    height: 8rem;
    border-radius: 2rem;
}
