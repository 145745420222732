.head {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::after {
        z-index: 1;
        content: '';
        height: 640px;
        width: 640px;
        background: var(--theme-main);
        display: block;
        border-radius: 640px;
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
    }
}

.wrapper {
    background: var(--light-blue);
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 90%;
    display: block;
    margin: 1rem auto;
    border-radius: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
}

.title {
    color: var(--theme-main);
    margin-top: 0;
    font-size: 1rem;
}

.txt {
    font-size: .8rem;
    margin-top: .5rem;
    margin-top: 0;
}
