$socialPaddingH: 1.1rem;

.social {
    display: block;
    background-color: var(--white);
    padding-top: 1.5rem;
    padding-left: $socialPaddingH;
    padding-right: $socialPaddingH;
    height: auto;
    overflow: hidden;
}

.greeting {
    margin-bottom: .5rem;
}
.title {
    margin-top: 0;
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 1.5rem;
}

.notice, .search {
    position: absolute;
    background: var(--theme-main);
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    margin-top: -1rem;
    border-radius: 2rem;
    right: 0;
    
    img {
        height: 60%;
    }
}

.search {
    right: 3rem;
}

.notice {
    &::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 10px;
        background: var(--manhattan);
    }
}

.top {
    position: relative;
}

.list {
    text-align: center;
    margin-right: 1rem;
    &s {
        display: flex;
        width: calc(100% + $socialPaddingH);
        overflow: auto;
        margin-bottom: 2rem;
    }
    &__img {
        @extend .picImg;
    }
    img {
        width: 100%;
    }
}

.bg {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: .5;
    pointer-events: none;
}

.add {
    --addWidth: 4rem;
    position: fixed;
    bottom: calc(var(--nav-height) * 1.2);
    background: linear-gradient(135deg,  rgba(50,197,255,1) 0%,rgba(155,228,255,1) 100%);
    width: var(--addWidth);
    height: var(--addWidth);
    border-radius: var(--addWidth);
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    border: 0;
    box-shadow: 0 0 2px 2px rgba(0,0,0,.2);

    img {
        width: calc(var(--addWidth) / 3);
        border: 2px solid #ffffff;
        border-radius: 5px;
        padding: 2px;
        overflow: hidden;
    }
}

.card {
    margin-bottom: 2rem;
    &__top {
        display: flex;
        align-items: center;
        &__img {
            &__inner {
                @extend .picImg;
            }
        }
        &__txt {
            padding-left: .5rem;
            &__title {
                font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
            }
        }
        &__tool {
            margin-left: auto;
        }
    }
    &__img {
        display: block;
        margin-top: 1rem;
        border-radius: 25px;
        overflow: hidden;
        box-shadow: 0px 5px 5px rgba(0,0,0,.3);
        img {
            width: 100%;
            display: block;
        }
    }
    &__btm {
        &__icos {
            display: flex;
            margin-top: 1rem;
            margin-bottom: 1rem;
            gap: .5rem;
            &__ico--last {
                margin-left: auto;
            }
        }
        &__info {
            display: flex;
            align-items: center;
            &__img {
                span {
                    display: inline-block;
                    vertical-align: top;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 2rem;
                    overflow: hidden;
                    background: var(--light-blue);
                    border: 2px solid var(--white);
                    margin-left: -1rem;
                    &:first-child {
                        margin-left: 0;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            &__liked {
                font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                font-weight: normal;
                font-size: .8em;
                margin-left: auto;
            }
        }
    }
}

.picImg {
    border-radius: 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
    overflow: hidden;
    border: 4px double var(--theme-main);
    img {
        width: 100%;
    }
}


.createPlatform {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    z-index: 65200;
    &__wrapper {
        background: #ffffff;
        border-radius: 1rem 1rem 0 0;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        &__head,
        &__body {
            padding: 1rem;
        }
        &__head {
            font-family: "Poppins", Arial, "Helvetica Neue", Helvetica, sans-serif;
            border-bottom: 2px solid var(--light-blue);
            position: relative;
            font-size: 1.5rem;

            &__close {
                position: absolute;
                top: 50%;
                right: 1.5rem;
                border: 0;
                background: transparent;
                padding: 0;
                transform: translateY(-50%);

                img {
                    display: block;
                    width: 1.2rem;
                }
            }
        }
        &__body {
            &__button {
                background: transparent;
                border: 0;
                padding: .5rem 0;
                display: block;
                font-size: 1.2rem;
                line-height: 1.5em;
                padding-left: 2.5rem;
                position: relative;
                &__img {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 1.5rem;
                }

            }
        }
    }
}