.pets {
    display: block;
    background: var(--light-grey);
    height: calc(100vh - var(--nav-height));
    position: relative;
}
.head {
    display: block;
    overflow: hidden;
    background: var(--white);
    position: relative;
}
.title {
    display: block;
    margin: 1rem 0;
    text-align: center;
    font-family: var(--tfont);
}
.add {
    display: block;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: var(--sky-blue);
    padding: .5rem;
    border: 2px solid var(--light-blue);
    border-radius: .5rem;

    img {
        display: block;
    }
}
.body {
    display: block;
    padding: 1rem;
}
.lists {
    display: block;
}
.item {
    background: var(--white);
    display: flex;
    padding: 1rem;
    border-radius: .5rem;
    align-items: center;
    color: var(--black);

    &__img {
        &__wrapper {
            width: 3rem;
            height: 3rem;
            display: block;
            border-radius: 3rem;
            overflow: hidden;
            border: 1px solid var(--sky-blue);
        }
    }
    &__txt {
        margin-left: 1rem;
        &__title {
            display: block;
            font-size: 1.2rem;
        }
        &__txt {
            display: block;
            font-size: .8rem;
            color: var(--grey);
            margin-top: .5rem;
        }
    }
    &__ico {
        display: block;
        margin-left: auto;
    }
}

.add_bottom_btn {
    display: block;
    position: absolute;
    bottom: 1rem;
    line-height: 3rem;
    box-sizing: border-box;
    left: 1rem;
    right: 1rem;
    padding: 0;
    border-radius: .5rem;
    border: 1px solid var(--theme-main);
    color: var(--theme-main);
    font-family: var(--tfont);
    font-weight: bold;
}