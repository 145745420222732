.promotion {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 0 .5rem;
    color: var(--black);
    margin-bottom: 1rem;
}

.innerwrapper {
    background: var(--theme-main) url('../../../public/images/promotion-bg.png') right center no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
    border-radius: 1.5rem;
}

.txt {
    font-size: 1.5rem;
    font-weight: bold;
}

.btn {
    margin-top: 1rem;
    font-size: 1rem;
    display: block;
    background: #ffffff;
    text-align: center;
    line-height: 2rem;
    border-radius: 2rem;
}
