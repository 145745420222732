.commonHead {
    display: block;
    position: sticky;
    top: 0;
    &__top {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__tools {
            &__btn {
                width: 60px;
                height: 60px;
                border: 2px solid var(--theme-main);
                // border-radius: 60px;
                overflow: hidden;
                background: var(--white);
                & + & {
                    margin-left: .5rem;
                }
            }
        }
    }
}
