.petCard {
    background: var(--manhattan) url('../../../public/images/pattern.svg') 100% center no-repeat;
    background-size: contain !important;
    border: 4px var(--white) solid;
    color: var(--black);
    outline: none;
    width: 140px;
    padding: 0;
    border-radius: 5px 20px;
    flex-direction: column;
    margin-right: .5rem;
    overflow: hidden;
    position: relative;
    &__imgwrapper {
        height: 180px;
        width: 100%;
        overflow: hidden;
        display: block;
        &::after {
            content: '';
            position: absolute;
            background: url('../../../public/images/1062.jpg') center center repeat;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background-size: auto 100%;
            filter: grayscale(10);
            mix-blend-mode: soft-light;
            opacity: .3;
        }
    }
    &__img {
        &__img {
            position: relative;
            z-index: 2;
            height: 200px;
            transform: translateX(-50%);
            left: 50%;
            border-radius: 80px;
            overflow: hidden;
        }
    }
    &__name {
        position: absolute;
        z-index: 1;
        font-size: 2.5rem;
        font-weight: bold;
        margin-top: .5rem;
        margin-bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        top: 3px;
        text-shadow: 2px 2px 0 #ffffff;


        .active & {
            text-shadow: 2px 2px 0 #000000;
        }
    }
    &__type {
        position: absolute;
        z-index: 2;
        padding-top: .5rem;
        padding-bottom: .5rem;
        background-color: var(--white);
        width: 100%;
        bottom: 0;
        left: 0;
        text-align: center;
        font-weight: lighter;
        color: var(--black);
        font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    }
    &.active {
        color: var(--white);
        background: var(--theme-main) url('../../../public/images/pattern.svg') 100% center no-repeat;
    }
}
