.title {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-weight: bold;
    color: var(--white);
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 2.5rem;
    line-height: 2.8rem;
    filter: drop-shadow(2px 4px 6px rgba($color: #000000, $alpha: .3));
    margin-top: 0;
    &::after {
        content: '';
        width: 4rem;
        height: 3rem;
        display: block;
        background: url('../../../public/images/ico/pattern-2.svg') center center no-repeat;
        position: absolute;
        left: 100%;
        top: 0;
        opacity: .3;
    }
    span {
        margin-left: 1rem;
    }
}

.pt {
    font-size: .5em;
    font-weight: normal;
}

.home {
    background: #edb507;
    height: 100dvh;
    position: relative;
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        background: url('../../../public/images/1062.jpg') center center repeat;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-size: auto 100%;
        filter: grayscale(10);
        mix-blend-mode: soft-light;
        opacity: .3;
    }
    &__img {
        position: absolute;
        z-index: 2;
        height: 80vh;
        top: 10vh;
        left: 50%;
        transform: translateX(-50%);
        filter: drop-shadow(2px 4px 6px white);
    }
    &__details {
        width: 100%;
        box-sizing: border-box;
        z-index: 3;
        overflow: hidden;
        background: var(--white);
        text-align: center;
        padding: 1rem;
        border-radius: 50% 50% 0 0;
        box-shadow: 2px 4px 6px rgba($color: #000000, $alpha: .6);
        position: absolute;
        bottom: 0;
        padding-top: 3rem;
        padding-bottom: calc(var(--nav-height) + 1rem);

        &__title {
            font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
            margin-bottom: .5rem;
            margin-top: 0;
        }
    }
}

.details {
    background: var(--white);
    min-height: 100dvh;
    overflow: hidden;
    // padding: 1rem .5rem;
    position: relative;
    &::after {
        content: '';
        display: block;
        width: 40rem;
        height: 30rem;
        position: absolute;
        top: -5rem;
        right: -4rem;
        pointer-events: none;
        background: url('../../../public/images/pattern-1.svg') center center no-repeat;
        background-size: contain !important;
    }
    &__back {
        background: var(--white);
        border: 2px solid var(--theme-main);
        position: absolute;
        top: .5rem;
        left: 1rem;
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        font-size: 0;
        z-index: 50;
        &::after {
            content: '';
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 7.5px 10px 7.5px 0;
            border-color: transparent var(--theme-main) transparent transparent;
            transform: rotate(0deg);
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -7.5px;
            margin-left: -7.5px;
        }
    }
    // &::after {
    //     content: '';
    //     pointer-events: none;
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     background: url('../../../public/images/demo-detail-page.png') center -355px no-repeat;
    //     opacity: .2;
    //     background-size: 101% auto;
    // }
}

.top {
    text-align: center;
    padding: 1rem .5rem;
    border-radius: 25px 25px 0 0;
    position: relative;
    overflow: hidden;

    > div {
        position: relative;
        z-index: 2;
    }

    &::after {
        content: '';
        z-index: 1;
        background: linear-gradient(to right,  #ffffff 0%,#000000 80%);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        mix-blend-mode: soft-light;
    }
}

.name {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: bold;
    border-bottom: 2px dotted var(--white);
    color: var(--theme-main);
    padding-bottom: .5rem;
}

.age, .weight {
    display: inline-flex;
    width: 100%;
    background: var(--theme-main);
    padding: .3rem 1rem;
    box-sizing: border-box;
    border-radius: 15px;
    align-items: center;
    border: 2px solid var(--white);
    color: var(--white);
    &__ico {
        width: 2.5rem;
        height: 2.5rem;
        background: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2.5rem;
        img {
            width: 1.5rem;
            transform: rotate(15deg);
        }
    }
    &__txt {
        padding-left: 1rem;
        text-align: left;
        &__title {
            font-size: .8em;
        }
        &__number {
            font-size: 2rem;
        }
    }
}

.btn__wrapper {
    display: flex;
    justify-content: space-between;

    > div {
        width: calc(50% - .5rem);
    }
}


.pic {
    width: 5rem;
    height: 5rem;
    overflow: hidden;
    border-radius: 5rem;
    background: var(--white);
    margin: 0 auto;
    img {
        width: 100%;
    }
}

.calendar {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    backdrop-filter: blur(100px);
    position: relative;
    z-index: 5;
    img {
        width: 100%;
    }
}
