.header {
    background: var(--white);
    position: fixed;
    top: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0px 10px 10px rgba(0,0,0,.2);
    width: 100%;
    display: flex;
    height: var(--header-height);
    justify-content: center;
    box-sizing: border-box;
    left: 0;
    z-index: 1000;
}

.container {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;

    img {
        max-height: 100%;
    }
}

.nav {
    --nav-display: block;
    display: var(--nav-display);
    > a {
        display: inline-block;
        color: var(--black);
        vertical-align: top;
        & + a {
            margin-left: 1rem;
        }
    }
}

.submenu {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 1rem;
    &__head {
        display: none;
        @media screen and (max-width: 818px) {
            display: block;
        }
    }
    &__body {
        display: block;
        background: var(--white);
        @media screen and (max-width: 818px) {
            padding: 1rem;
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
        }
        &__link {
            color: var(--black);
            @media screen and (max-width: 818px) {
                display: block;
                width: 150px;
            }
            & + & {
                margin-left: 1rem;
                @media screen and (max-width: 818px) {
                    margin-top: 1rem;
                    margin-left: 0;
                }
            }
        }
        .submenu:hover & {
            display: block;
        }
    }
}

.home {
    height: 100%;
    display: block;
    margin-right: auto;
    img {
        display: block;
    }
}

.btn_wrapper {
    margin-left: 2rem;
}
