
:root {
    --knowmore-content-padding-right: 2rem;
    --knowmore-margin-top: 6rem;
    --knowmore-img-margin-bottom: 0;
    --knowmore-img-position: absolute;
    --knowmore-flex-direction: row;
    --knowmore-display: flex;
    --img-width: 40%;
    --knowmore-content-width: 60%;
    @media screen and (max-width: 818px) {
        --knowmore-content-padding-right: 0;
        --knowmore-content-width: 100%;
        --knowmore-img-margin-bottom: 1rem;
        --img-width: 100%;
        --knowmore-margin-top: 0rem;
        --knowmore-img-position: relative;
        --knowmore-flex-direction: column-reverse;
    }
}

.knowmore {
    flex-direction: var(--knowmore-flex-direction);
    display: var(--knowmore-display);
    margin-top: var(--knowmore-margin-top);
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--light-blue);
    box-shadow: 0 5px 5px rgba(0,0,0,.2);
    padding: 1rem;
    position: relative;
    min-height: 10rem;
    max-width: var(--web-inner-width);
    width: 100%;
    box-sizing: border-box;
}

.content {
    width: var(--knowmore-content-width);
    padding-right: var(--knowmore-content-padding-right);
    box-sizing: border-box;
}

.title {
    margin-top: 0;
}

.img {
    width: var(--img-width);
    position: var(--knowmore-img-position);
    bottom: 0;
    right: 0;
    margin-bottom: var(--knowmore-img-margin-bottom);
    &::after {
        content: '';
        display: block;
        height: 6rem;
        width: 100%;
        background: url('../../../../public/images/vet-background.png') center center no-repeat;
        background: cover !important;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        mix-blend-mode: multiply;
    }

    img {
        position: relative;
        z-index: 2;
        display: block;
        margin: 0 auto;
        height: 15rem;
    }
}
