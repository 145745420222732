.petHealthChecklist {
    h2 {
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .valueInput {
        // margin-left: 10px;
        display: block;
        width: 100%;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 80px;
    }
}

.add {
    position: absolute;
    background: var(--theme-main);
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    top: 50%;
    margin-top: -1rem;
    img {
        display: block;
    }
}