.head {
    display: block;
    position: relative;    
    overflow: hidden;
    
}
.back {
    display: block;
    position: absolute;
    left: .5rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: 0;
    background: transparent;
    img {
        display: block;
        width: 1.5rem;
    }
}
.title {
    display: block;
    text-align: center;
    font-family: var(--tfont);
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem;
}