$upload-border: 2px dotted var(--grey);
$upload-margin-bottom: 1rem;
$who-list-item-imgwrapper-width: 4rem;

.post {
    display: block;
    background: var(--white);
    height: 100vh;
    padding-bottom: var(--nav-height);
    box-sizing: content-box;
    padding-left: .5rem;
    padding-right: .5rem;
}

.body {display: block;}
.upload, .your_mind, .who {
    margin-bottom: $upload-margin-bottom;
}
.upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: $upload-border;
    border-radius: 2rem;
    padding: 2rem;
    &__ico {
        display: block;
        width: 1.5rem;
    }
    &__txt {
        display: block;
        margin-bottom: 0;
    }
}
.your_mind {
    &__txt {
        display: block;
    }    
}
.who {
    &__title {display: block;}
    &__list {display: block;}
    &__list {
        &__item {
            text-align: center;
            display: inline-block;

            &+& {
                margin-left: 1rem;
            }
            &__imgwrapper {
                margin: 0 auto;
                width: $who-list-item-imgwrapper-width;
                height: $who-list-item-imgwrapper-width;
                border-radius: $who-list-item-imgwrapper-width;
                overflow: hidden;
                border: 2px solid var(--sky-blue);
                &__img {
                    display: block;
                    width: 100%;
                    object-fit: cover;
                }
            }
            &__txt {
                display: block;
                margin: .5rem 0 0;
            }
        }
    }
}
.submit {
    display: block;
    width: 100%;
    background: var(--light-blue);
    border: 0;
    padding: 1rem;
    border-radius: 2rem;
    font-size: 1.2rem;
}