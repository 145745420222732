.shortCuts {
    padding: 1rem .5rem;
    --shortCutIcoSize: 4rem;

    &__top {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__item {
        text-align: center;
        display: inline-block;
        & + & {
            margin-left: 1rem;
        }
        &s {
            display: flex;
            width: 100%;
            overflow-x: auto;
        }
        &__ico {
            display: flex;
            width: var(--shortCutIcoSize);
            height: var(--shortCutIcoSize);
            background-color: var(--white);
            border-radius: var(--shortCutIcoSize);
            align-items: center;
            justify-content: center;
            margin-bottom: .5rem;
            img {
                width: calc(var(--shortCutIcoSize) * 2.5 / 5);
            }
        }
        &__txt {
            font-size: .8rem;
        }
    }
}
