.btn {
    display: block;
    width: 100%;
    background: var(--theme-main) url('../../../public/images/pattern.svg') right center no-repeat;
    background-size: 80px auto;
    color: var(--white);
    font-size: 1rem;
    border: 0;
    line-height: 40px;
    border-radius: 50px;
    -webkit-box-shadow: 2px 2px 2px 2px rgba(0,0,0,.1);
            box-shadow: 2px 2px 2px 2px rgba(0,0,0,.1);
}
