

.points {
    --points-btn-margin-top: 2rem;
    --points-title-font-size: 2rem;
    --points-title-margin-top: 0;
    --points-content-padding: 0;
    --points-imgwrapper-width: 50%;
    --points-flex-direction: row;
    @media screen and (max-width: 818px) {
        --points-btn-margin-top: 0;
        --points-point-titles-font-size: 1rem;
        --points-title-font-size: 1.5rem;
        --points-content-padding: 1rem;
        --points-imgwrapper-width: 100%;
        --points-flex-direction: column;
        --points-title-margin-top: 1rem;
    }
    display: flex;
    align-items: center;
    // max-width: var(--web-inner-width);
    width: 100%;
    margin: 3rem auto;
    flex-direction: var(--points-flex-direction);
    padding: 0 var(--points-content-padding);
    box-sizing: border-box;
}

.imgwrapper {
    display: block;
    width: var(--points-imgwrapper-width);
    .inner {
        padding-top: #{301/430*100%};
        position: relative;
    }
    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .img {
        &1, &2, &3 {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            opacity: 0;
        }
        &1 {
            z-index: 3;
        }
        &2 {
            z-index: 2;
        }
        &3 {
            z-index: 1;
        }
    }
}

.content {
    width: var(--points-imgwrapper-width);
    overflow: hidden;
    max-width: 600px;
    display: block;
}

.title {
    font-size: var(--points-title-font-size);
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    margin-top: var(--points-title-margin-top);
}

.points {
    &__point {
        &__titles {
            font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
            font-size: var(--points-point-titles-font-size);
        }
        &__txt {
            margin-left: 1rem;
        }
    }
}

.btn {
    margin-top: var(--points-btn-margin-top);
}
