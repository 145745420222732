.home, .pets {
    &__top, &__btm {
    }
    &__top {
        padding: 1rem .5rem;
    }
    &__btm {
        padding: 0 .5rem 1rem;
    }
}

.home {
    display: block;
    background: var(--light-grey);
    padding-bottom: 2rem;
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__tools {
            &__btn {
                width: 60px;
                height: 60px;
                border: 2px solid var(--theme-main);
                border-radius: 60px;
                overflow: hidden;
                background: var(--white);
                & + & {
                    margin-left: .5rem;
                }
            }
        }
    }
}

.pets {
    width: 100%;
    overflow: hidden;
    &__top {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: bold;
    }
    &__btm {
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;

        & > * {
            flex: 0 0 134px;
        }
    }
}
