.nearbyVet {
    display: block;
    padding: 1rem .5rem;
    &__wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        display: flex;
    }
}

.title {
    font-size: 1rem;
    margin-top: 0;
}

.card {
    width: 240px;
    background-color: var(--white);
    border-radius: 15px 0;
    margin-right: 1rem;
    &__img {
        width: 100%;
        overflow: hidden;
        border-radius: 15px 0;
        border: 2px solid var(--white);
        border-bottom: 0;
        padding-top: #{9/16*100%};
        position: relative;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            object-fit: cover;
            display: block;
        }
    }
    &__content {
        padding: .5rem;
        color: var(--black);
        &__name {
        }
        &__phone {
            display: block;
            margin-top: .5rem;
            font-size: .8em;
        }
    }
}
