.question {
    display: block;
    background: var(--white);
    height: 100vh;
    padding-bottom: var(--nav-height);
    box-sizing: content-box;
    padding-left: .5rem;
    padding-right: .5rem;
}

.notice {
    background: var(--light-blue);
    overflow: hidden;
    width: calc(100% + 1rem);
    margin-left: -.5rem;
    padding: .5rem;
    padding-left: 4rem;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 2rem;
    &::before {
        content:'';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        background: url('../../../public/images/ico/bell.svg') center center no-repeat;
        background-size: contain !important;
    }
}

.questionwrapper {
    margin-bottom: 2rem;
    &__title {
        display: block;
    }
}
.word__limit {
    text-align: right;
    color: var(--grey);

    .error & {
        color: var(--red);
    }
}
