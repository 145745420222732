

:root {
    --point-width: 25%;
    --point-overflow: hidden;
    --point-flex-wrap: wrap;
    @media screen and (max-width: 818px) {
        --point-width: 50vw;
        --point-overflow: auto;
        --point-flex-wrap: nowrap;
    }
}

.details {
    display: block;
    text-align: center;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
}

.title {
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: var(--theme-main);
}

.txt, .points {
    max-width: 800px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.point {
    &s {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: var(--point-flex-wrap);
        gap: 2rem;
        width: 100%;
        overflow-x: var(--point-overflow);
        padding-bottom: 1rem;
    }

    padding: 1rem;
    box-shadow: 0px 2px 2px rgba(0,0,0,.2);
    width: var(--point-width);
    flex: 0 0 var(--point-width);
    border: 1px solid var(--light-grey);
    .ico {
        padding: .5rem;
        background: var(--light-blue);
        display: flex;
        border-radius: 0 15px 0 15px;
        height: 2rem;
        width: 2rem;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        img {
            height: 2rem;
        }
    }
    &__title {
        margin-bottom: .5rem;
        font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    }
    &__txt {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.col2 {
    flex: 0 0 40% !important;
}

.col4 {
    flex: 0 0 16% !important;
}
