.accordion {
    display: block;
    &__content {
        height: 0;
        overflow: hidden;
        width: 100%;

        .active & {
            margin-top: 1rem;
            height: auto;
        }
    }
}

.title {
    position: relative;
    padding-right: 2rem;
    margin-bottom: 0;
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -5px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 9px 11px 9px;
        border-color: transparent transparent var(--theme-main) transparent;
        transform: rotate(180deg);
        transition: all 500ms ease-in;

        .active & {
            transform: rotate(0deg);
        }
    }
}
