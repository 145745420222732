.record {
    display: block;
    min-height: 100%;
    background: var(--white);
}

.tabs {
    display: flex;
    &__tab {
        flex: 30%;
        background: var(--white);
        color: var(--theme-main);
        border: 0;
        padding: .5rem;
        &.active {
            background: var(--theme-main);
            color: var(--white);
        }
    }
}

.contents {
    display: block;
    &__content {
        &__lists {
            &__list {
                padding: 1rem;
                border-bottom: 1px solid var(--light-grey);
                display: block;
                position: relative;
                &__txt {
                    &__arrow {
                        &.back {
                            right: auto;
                            left: 1rem;
                            transform: rotate(180deg);
                        }
                        position: absolute;
                        right: 1rem;
                        top: 50%;
                        margin-top: -1rem;
                        box-shadow: 0 0 2px 2px rgba(0,0,0,.1);
                        background: var(--sky-blue);
                        border-radius: 100%;
                        border: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 2rem;
                        height: 2rem;
                        img {
                            width: 80%;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.details {
    display: block;
    &__head {
        position: relative;
        padding: 1rem;
        padding-left: 4rem;
        border-bottom: 1px solid var(--light-grey);
    }
}
