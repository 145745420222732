.footer {
    display: block;
    text-align: center;
    background: var(--theme-main);
    color: var(--theme-main);
    // border-radius: 25px 25px 0 0;
    padding: 1rem;
    overflow: hidden;
}

.logo {
    width: 50px;
    // margin-bottom: 1rem;
}

.top {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background: var(--white);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav a {
    display: inline-block;
    & + a {
        margin-left: 1rem;
    }
}
